
.state-bar {
    display: block;
    width: 30px;
    height: 8px;
    border-radius: 999px;
    border: 1px solid var(--v-grey-lighten4);
    margin-left: 8px;
}
@media (min-width: 768px) {
}
