
::v-deep {
    .board-comments__list {
        border-top: 2px solid var(--v-grey-darken4);
        > li {
            border-bottom: 1px solid var(--v-grey-lighten3);
            padding: 16px 0;
        }
    }
    .comment-input {
        .v-textarea {
            height: 80px;
            border-radius: 8px 0 0 8px !important;
            ::v-deep {
                .v-input__slot {
                    padding: 12px;
                }
                textarea {
                    padding-bottom: 0;
                }
            }
        }
        .v-btn {
            height: 100%;
            border-radius: 0 8px 8px 0;
        }
    }

    @media (min-width: 768px) {
        .comment-input {
            .v-textarea {
                .v-input__slot {
                    padding: 16px;
                }
            }
        }
    }
}
