
.v-btn:not(.v-btn--fab, .v-btn--icon).v-size--x-large {
    .v-icon {
        color: var(--v-grey-lighten3);
    }
}
.v-btn__text {
    color: var(--v-grey-darken4);
}
.v-btn.v-btn--active {
    .v-btn__text {
        color: #fff;
    }
}
