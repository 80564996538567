
.result-list-item {
    &:nth-child(1) {
        > .v-card {
            color: var(--v-primary-base);
        }
    }
    &:nth-child(2) {
        > .v-card {
            color: var(--v-secondary-base);
        }
    }
    &:nth-child(3) {
        > .v-card {
            color: var(--v-tertiary-base);
        }
    }
    &:nth-child(4) {
        > .v-card {
            color: #37b0b8;
        }
    }
    > .v-card {
        border-width: 2px;
        border-color: currentColor;
    }
}
.responsive-radius {
    border-radius: 16px !important;
}
@media (min-width: 768px) {
    .responsive-radius {
        border-radius: 999px !important;
    }
}
