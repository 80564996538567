
.swiper-slide {
    position: relative;
}
.swiper-pagination {
    left: 24px;
    bottom: 24px;
    top: initial;
}
.swiper-pagination-bullets {
    margin: -6px;
    ::v-deep {
        .swiper-pagination-bullet {
            margin: 6px;
            background-color: #fff;
            width: 8px;
            height: 8px;
            opacity: 0.4;
            &-active {
                width: 20px;
                height: 20px;
                background-color: transparent;
                border: 1px solid #fff;
                position: relative;
                opacity: 1;
                &::after {
                    content: "";
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: #fff;
                    border-radius: 50%;
                }
            }
        }
    }
}

.information-list {
    > li {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
