
.place-list-item {
    &__title {
        height: 44px;
        font-weight: 700;
        padding: 8px 16px;
        display: flex;
        align-items: center;
    }
}
@media (min-width: 768px) {
    .place-list-item {
        &__title {
            height: 48px;
        }
    }
}
