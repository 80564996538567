
#guide {
    margin-top: -50px;
    padding-top: 100px;
}
@media (min-width: 768px) {
    #guide {
        margin-top: -60px;
        padding-top: 120px;
    }
}

@media (min-width: 1024px) {
    #guide {
        margin-top: -80px;
        padding-top: 160px;
    }
}
