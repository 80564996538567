
::v-deep .v-btn:not(.v-btn--fab, .v-btn--icon).v-size--x-large {
    height: 50px;
    border-radius: 8px;
    padding: 0 20px;
    justify-content: space-between;
    font-weight: 400;
    &.v-btn--active {
        background-color: var(--v-primary-base);
        font-weight: 700;
        &::before {
            display: none;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    ::v-deep .v-btn:not(.v-btn--fab, .v-btn--icon).v-size--x-large {
        height: 56px;
        padding: 0 24px;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
